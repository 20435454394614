
import React from "react" 
import { Helmet } from "react-helmet";     
import { PublikaceDetail } from "../templates/publikace/PublikaceDetail"; 
import { Router } from "@reach/router"
import { ApolloProvider } from "@apollo/client";
import { apolloClient  } from "../client/apollo";
 
const PublikaceDetailPage = ({ location}) => {

  return (
      <>
         <Helmet>
          <meta charSet="utf-8" />
          <title>Publikace | Exekutorský úřad Praha 9</title> 
        </Helmet>  

        <ApolloProvider client={apolloClient}>
            <Router> 
                <PublikaceDetail path="/publikace-detail/:alias" location={location} />  
            </Router>
          </ApolloProvider>
      </>

  )
}
 
export default PublikaceDetailPage
 