
import React, {useEffect} from "react"
import Layout from "../../components/layout/Layout"
import { useQuery } from "@apollo/client";
import { ARTICLE } from "../../query/articles";
import { Helmet } from "react-helmet";
import { format } from 'date-fns'
import { SRLWrapper } from "simple-react-lightbox";
import { Loading } from "../../components/layout/Loading";

export const PublikaceDetail = ({location}) => {

    let url = null;
    let path
    path = location.pathname.split('/');
    if(path[1]==='publikace-detail') {
        url = path[2];
    }  

     
    let queryName = ARTICLE;
    const {loading, data} = useQuery(queryName, {
        variables: {
            url: url
        }
    });

    let item = data?.articleFindOneByUrl || null; 
    let datum

    
    item?.issueDate && (datum = format(new Date(item.issueDate),'dd. MM. yyyy'))
    
    

     

    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Publikace | Exekutorský úřad Praha 9</title> 
        </Helmet>
        <Layout location={location} pageContext={{title: item?.name, pageId: '41', parent: '37'}}> 
        {(loading || !item) ? 
            <Loading loading={loading} />
        :
    
         <div>   
                
                <section className="max-w-6xl mx-auto mb-5" >
                
                        
                  <div className="rounded-lg bg-white overflow-hidden  "> 
                    <div className=" px-10 ">

                        <div className="lg:grid lg:grid-cols-3 gap-6">
                            
                             <div className="col-span-2">
                                    <div className="flex justify-end py-3 prose">
                                        <span className="text-gray-500 text-sm">{datum}</span> 
                                    </div>
                                <div dangerouslySetInnerHTML={{__html: item.prologue}} className=" prose p-5 bg-gray-100 rounded-md mb-10">
                                
                                </div>
                                {item.text && 
                                <>
                                     
                                    <div dangerouslySetInnerHTML={{__html: item.text}} className=" prose">
                                    
                                    </div>
                                </>
                                }

                                    
                                    
                             </div>
                             <div className="flex-shrink-0 max-w-sm mx-auto mt-10 mb-10 ">
                            {item?.img &&  
                                <SRLWrapper>
                                <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 mx-auto">
                                    
                                        <div   className={"overflow-hidden col-span-2 lg:col-span-3"  }>
                                            <img
                                                className="cursor-pointer object-cover min-h-full"
                                                src={item?.img}
                                                alt={item?.name}
                                            />
                                        </div>
                                    
                                
                                </div>
                                </SRLWrapper>
                            }
                              
                            </div>
                        </div>
                    </div> 
                  </div>
                </section>
            </div>
            }
        </Layout>
        </>
    )
}